import Helmet from "react-helmet";
import Footer from "../../components/footer";
import "./style.scss";
import NavBar from "../../components/navbar";

const Careers = () => {
  return (
    <>
      <Helmet>
        <title>Tekstrive - Careers</title>
      </Helmet>
      <NavBar />
      <div className="contact-container">
        <Hero />
        <ContactSection />
        <Footer />
      </div>
    </>
  );
};

const Hero = () => {
  return (
    <>
      <section className="section-global bg-shade-blue hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-tag mb-8">Careers</div>
              <h1 className="display-1">
                We are always here to ensure customer satisfaction
              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ContactSection = () => {
  const contactLinkData = [
    {
      title: "Software Developer",
      text: "Full Time",
      desc: "Develop efficient and scalable software applications using modern JavaScript frameworks and libraries such as React, Angular, or Vue.js. Collaborate with cross-functional teams to gather requirements and design innovative solutions using Agile methodologies. Write clean, maintainable, and reusable code following industry best practices and utilizing tools like ESLint and Prettier for code formatting and consistency. Implement responsive user interfaces with a focus on usability and accessibility, utilizing HTML5, CSS3, and frameworks like Bootstrap or Material-UI. Design and develop RESTful APIs using Node.js and Express.js to enable seamless communication between the front-end and back-end systems. Utilize databases like MongoDB or MySQL to store and retrieve data efficiently, writing optimized queries and database schemas. Troubleshoot and debug issues throughout the development lifecycle using tools like Chrome DevTools and logging libraries like Winston or Bunyan. Optimize application performance for maximum speed and scalability, utilizing caching mechanisms, lazy loading, and bundling techniques. Conduct code reviews using tools like GitHub or Bitbucket to ensure code quality, adherence to standards, and best practices. Work under supervision. Travel and/or relocation to unanticipated client sites in USA is required.",
      edu: "Bachelor’s degree in Computer Science/ IT/ Engineering (Any)/ or closely related field with Twelve (12) months of experience in the job offered or as an IT Consultant or Analyst or Programmer or Developer or related field.",
      exp: "Experience of Twelve (12) months working on HTML5, CSS3, JavaScript is required. Travel and/or relocation is required to unanticipated client sites within USA. International travel is not required. The frequency of travel is currently not known as it depends on the client and project requirement that cannot be currently anticipated. Employer provides Information technology services to various clients in USA and hence implementing projects will require such travel.",
      loc: "TekStrive LLC 291 S Preston Road, Unit 940 Prosper, TX 75078",
      link: "mailto:stact.team@email.com",
    },
    // {
    //   title: "Software Developer",
    //   text: "Full Time",
    //   desc: "Develop efficient and scalable software applications using modern JavaScript frameworks and libraries such as React, Angular, or Vue.js. Collaborate with cross-functional teams to gather requirements and design innovative solutions using Agile methodologies. Write clean, maintainable, and reusable code following industry best practices and utilizing tools like ESLint and Prettier for code formatting and consistency. Implement responsive user interfaces with a focus on usability and accessibility, utilizing HTML5, CSS3, and frameworks like Bootstrap or Material-UI. Design and develop RESTful APIs using Node.js and Express.js to enable seamless communication between the front-end and back-end systems. Utilize databases like MongoDB or MySQL to store and retrieve data efficiently, writing optimized queries and database schemas. Troubleshoot and debug issues throughout the development lifecycle using tools like Chrome DevTools and logging libraries like Winston or Bunyan. Optimize application performance for maximum speed and scalability, utilizing caching mechanisms, lazy loading, and bundling techniques. Conduct code reviews using tools like GitHub or Bitbucket to ensure code quality, adherence to standards, and best practices. Work under supervision. Travel and/or relocation to unanticipated client sites in USA is required.",
    //   edu: "Bachelor’s degree in Computer Science/ IT/ Engineering (Any)/ or closely related field with Twelve (12) months of experience in the job offered or as an IT Consultant or Analyst or Programmer or Developer or related field.",
    //   exp: "Experience of Twelve (12) months working on HTML5, CSS3, JavaScript is required. Travel and/or relocation is required to unanticipated client sites within USA. International travel is not required. The frequency of travel is currently not known as it depends on the client and project requirement that cannot be currently anticipated. Employer provides Information technology services to various clients in USA and hence implementing projects will require such travel.",
    //   loc: "TekStrive LLC 291 S Preston Road, Unit 940 Prosper, TX 75078",
    //   link: "mailto:stact.team@email.com",
    // },
  ];
  return (
    <>
      <section className="section-global">
        <div className="container container-2">
          <div className="row gy-4">
            {contactLinkData.map((e, i) => (
              <div className="col-lg-12 col-md-12" key={i}>
                <a href={e.link} className="contact-link">
                  <div className="contact-link-container d-flex align-items-center">
                    <div className="ms-3">
                      <div className="txt-1 fw-500 color-1">{e.title}</div>
                      <div className="txt-2">{e.text}</div>
                      <br />
                      <div className="txt-highlight">Posting Date</div>
                      <div className="txt-3">09-28-2024</div>
                      <br/>
                      <div className="txt-highlight">Job Duties</div>
                      <div className="txt-3">{e.desc}</div>
                      <br/>
                      <div className="txt-highlight">Experience</div>
                      <div className="txt-3">{e.exp}</div>
                      <br />
                      <div className="txt-highlight">Education</div>
                      <div className="txt-3">{e.edu}</div>
                      <br/>
                      <div className="txt-highlight">Location</div>
                      <div className="txt-3">{e.loc}</div>
                      <br/>
                      <div className="btn btn-primary">Apply Now</div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers;
